<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { ORGANIZATION_CUSTOMER_TYPE_CODE, ORGANIZATION_SUPPLIER_TYPE_CODE, ORGANIZATION_CUSTOMER_LEAD_CODE, ORGANIZATION_CUSTOMER_CODE, ORGANIZATION_SUPPLIER_CODE } from '@/const'
import { TYPES as LEAD_ORG_TYPES } from './store'
import { ROUTES as LEAD_ORG_ROUTES } from './router'
import { ROUTES as SUPPLIER_ORG_ROUTES } from '../supplier-organization/router'
import { ROUTES as CUSTOMER_ORG_ROUTES } from '../customer-organization/router'

import OrganizationCreateMixin from '../../../components/organization/mixins/OrganizationCreateMixin'

export default {
  name: 'LeadOrganizationCreate',
  mixins: [OrganizationCreateMixin],
  data () {
    return {
      LEAD_ORG_ROUTES,
      title: 'Extra organization',
      routeNameList: LEAD_ORG_ROUTES.LEAD_ORGANIZATION_LIST,
      routeNameDetail: LEAD_ORG_ROUTES.LEAD_ORGANIZATION_DETAIL,
      orgType: this.$route.params.orgType,
      isExtra: true
    }
  },
  computed: {
    ...mapGetters({
      ...LEAD_ORG_TYPES.GENERIC.organization.customerorganization.DETAIL.GETTERS,
      groups: LEAD_ORG_TYPES.GENERIC.organization.customerorganization.LIST.GETTERS.groups,
      organizationform: LEAD_ORG_TYPES.GENERIC.organization.customerorganization.LIST.GETTERS.organizationform,
      typologyChoices: LEAD_ORG_TYPES.GENERIC.organization.customerorganization.LIST.GETTERS.typologyChoices,
      employeeCategoryChoices: LEAD_ORG_TYPES.GENERIC.organization.customerorganization.LIST.GETTERS.employeeCategoryChoices,
      orgTypeChoices: LEAD_ORG_TYPES.GENERIC.organization.customerorganization.LIST.GETTERS.orgTypeChoices
    })
  },
  methods: {
    ...mapMutations({
      setGroups:
        LEAD_ORG_TYPES.GENERIC.organization.customerorganization.LIST.MUTATIONS.setGroups
    }),
    ...mapActions({
      ...LEAD_ORG_TYPES.GENERIC.organization.customerorganization.DETAIL.ACTIONS,
      getGroups: LEAD_ORG_TYPES.GENERIC.organization.customerorganization.LIST.ACTIONS.getGroups,
      getOrganizationform: LEAD_ORG_TYPES.GENERIC.organization.customerorganization.LIST.ACTIONS.getOrganizationform,
      getTypologychoices: LEAD_ORG_TYPES.GENERIC.organization.customerorganization.LIST.ACTIONS.getTypologychoices,
      getEmployeecategorychoices: LEAD_ORG_TYPES.GENERIC.organization.customerorganization.LIST.ACTIONS.getEmployeecategorychoices,
      getOrgtypechoices: LEAD_ORG_TYPES.GENERIC.organization.customerorganization.LIST.ACTIONS.getOrgtypechoices
    }),
    goToDetailPage (evt) {
      this.orgFilters = false
      this.showDuplicatedForm = false
      if (evt.orgTypes.includes(ORGANIZATION_CUSTOMER_LEAD_CODE)) {
        this.$router.push({ name: LEAD_ORG_ROUTES.LEAD_ORGANIZATION_DETAIL, params: { id: evt.orgId, orgType: ORGANIZATION_CUSTOMER_LEAD_CODE } })
      } else if (evt.orgTypes.includes(ORGANIZATION_CUSTOMER_TYPE_CODE)) {
        this.$router.push({ name: CUSTOMER_ORG_ROUTES.CUSTOMER_ORGANIZATION_DETAIL, params: { id: evt.orgId, orgType: ORGANIZATION_CUSTOMER_CODE } })
      } else if (evt.orgTypes.includes(ORGANIZATION_SUPPLIER_TYPE_CODE)) {
        this.$router.push({ name: SUPPLIER_ORG_ROUTES.SUPPLIER_ORGANIZATION_DETAIL, params: { id: evt.orgId, orgType: ORGANIZATION_SUPPLIER_CODE } })
      }
    }
  }
}
</script>
